  .CodeMirror {
    height: 595.5px;
    width: 100% !important;
    margin: auto !important;
    min-width: 200px !important;
    text-align: left !important;
  }
  
  .CodeMirror-scroll {
    overflow: scroll !important;
    margin-bottom: -50px;
    margin-right: -50px;
    padding-bottom: 0 !important;
    height: 100%;
    max-width: 100% !important;
    outline: none; /* Prevent dragging from highlighting the element */
    position: relative;
  }